import { PostBlocks } from '../../../../components/PostBlocks';
import { Stream } from '../../../../lib/graphql.document';
import { Loader } from '../../../../components/Loader';
import { useMediaBlocks } from '../../../../react-hooks/useMediaPosts';
import { useFetchWhenInView } from '../../../../react-hooks/useFetchWhenInView';
import { layout } from '../../../../styles/layout';

interface PostsListProps {
  isBlog: boolean;
  postId: string;
  promoPostId?: string;
}

const PostsList = ({ isBlog, postId, promoPostId }: PostsListProps) => {
  const { onFetchMore, mediaBlocks, hasNextPage } = useMediaBlocks({
    stream: isBlog ? Stream.Blog : Stream.Main,
    exclude: promoPostId ? [postId, promoPostId] : [postId],
  });
  const { intersectionObserverRef } = useFetchWhenInView(onFetchMore);

  if (mediaBlocks) {
    return (
      <>
        <PostBlocks className={layout} blocks={mediaBlocks} />
        {hasNextPage && <Loader className="mx-auto my-5" ref={intersectionObserverRef} />}
      </>
    );
  }

  return <Loader className="mx-auto my-5" ref={intersectionObserverRef} />;
};

export default PostsList;
export type { PostsListProps };
